// Generated by Framer (045d2ed)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["i_RhBg6C8"];

const serializationHash = "framer-Mfmnv"

const variantClassNames = {i_RhBg6C8: "framer-v-wck8lj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "i_RhBg6C8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wck8lj", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"i_RhBg6C8"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dlaXN0IE1lZGl1bQ==", "--framer-font-family": "\"Geist Medium\", \"Geist Medium Placeholder\", sans-serif", "--framer-font-size": "48px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77)))"}}>€1000</motion.p><motion.p style={{"--font-selector": "Q1VTVE9NO0dlaXN0IE1lZGl1bQ==", "--framer-font-family": "\"Geist Medium\", \"Geist Medium Placeholder\", sans-serif", "--framer-font-size": "48px", "--framer-text-color": "var(--extracted-2gxw0f, var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77)))"}}><motion.br className={"trailing-break"}/></motion.p></React.Fragment>} className={"framer-1t3wuz2"} fonts={["CUSTOM;Geist Medium"]} layoutDependency={layoutDependency} layoutId={"esPC0THlQ"} style={{"--extracted-2gxw0f": "var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77))", "--extracted-r6o4lv": "var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Mfmnv.framer-1lgn242, .framer-Mfmnv .framer-1lgn242 { display: block; }", ".framer-Mfmnv.framer-wck8lj { height: 115px; overflow: visible; position: relative; width: 153px; }", ".framer-Mfmnv .framer-1t3wuz2 { flex: none; height: auto; left: 0px; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 115
 * @framerIntrinsicWidth 153
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercnEhuk4t1: React.ComponentType<Props> = withCSS(Component, css, "framer-Mfmnv") as typeof Component;
export default FramercnEhuk4t1;

FramercnEhuk4t1.displayName = "free";

FramercnEhuk4t1.defaultProps = {height: 115, width: 153};

addFonts(FramercnEhuk4t1, [{explicitInter: true, fonts: [{family: "Geist Medium", source: "custom", url: "https://framerusercontent.com/assets/cQQzQe0n7xYuuDfkgxZD2N9Q.woff2"}]}], {supportsExplicitInterCodegen: true})